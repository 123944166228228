<template>
    <div class="container">
        <Detail v-model:detailRow="detailRow" />
        <FilterBlock @search="search" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 2000 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="detailRow = record">详情</a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-button></a-button>
                        <a-button type="primary" @click="handleExport">导出报表</a-button>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import Detail from "./detail";
import { jsonToUrlParams, showMessage } from "@/utils/common";
import { apiJCStepWorkHoursList } from "@/api";
import { baseURL } from "@/utils/config";
import { useStore } from "vuex";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "项目编码",
        dataIndex: "projectCode",
    },
    {
        title: "项目名称",
        dataIndex: "projectName",
    },
    {
        title: "工序编码",
        dataIndex: "stepCode",
    },
    {
        title: "工序名称",
        dataIndex: "stepName",
    },
    {
        title: "标准工时(/h)",
        dataIndex: "totalStandardWorkHours",
    },
    {
        title: "实际工时(/h)",
        dataIndex: "totalWorkHours",
    },
    {
        title: "异常工时(/h)",
        dataIndex: "totalExceptionWorkHours",
    },
    {
        title: "工序质检工时(/h)",
        dataIndex: "totalJcStepCheckHours",
    },
    {
        title: "生产总时长(/h)",
        dataIndex: "totalHours",
    },
    {
        title: "操作",
        dataIndex: "operate",
        fixed: "right",
        width: 100,
    },
];
export default defineComponent({
    components: {
        FilterBlock,
        Detail,
    },
    setup() {
        const { getters } = useStore();
        const state = reactive({
            detailRow: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiJCStepWorkHoursList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const handleExport = () => {
            const {
                params,
                pagination: { current, pageSize },
            } = state;
            let url = `${baseURL}/web/mes-stats/export-project-step-work-hour-page`;
            url =
                url +
                jsonToUrlParams({
                    pageNum: current,
                    pageSize,
                    ...params,
                    "mes-token": getters["user/token"],
                });
            window.open(url);
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            getTabelData,
            handleExport,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
