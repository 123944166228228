<template>
    <a-drawer
        v-model:visible="visible"
        title="集成工序工时详情"
        @cancel="handleCancel"
        :destroyOnClose="true"
        :closable="false"
        @close="handleCancel"
    >
        <template #extra>
            <a-space direction="horizontal" :size="8">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleCancel">关闭</a-button>
            </a-space>
        </template>
        <a-descriptions :column="2">
            <a-descriptions-item label="项目编码">
                {{ detailRow?.projectCode }}
            </a-descriptions-item>
            <a-descriptions-item label="项目名称">
                {{ detailRow?.projectName }}
            </a-descriptions-item>
            <a-descriptions-item label="工序编码">{{ detailRow?.stepCode }} h</a-descriptions-item>
            <a-descriptions-item label="工序编码">{{ detailRow?.stepName }} h</a-descriptions-item>
            <a-descriptions-item label="标准工时">
                {{ detailRow?.totalStandardWorkHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="实际工时">
                {{ detailRow?.totalWorkHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="异常工时">
                {{ detailRow?.totalExceptionWorkHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="工序质检工时">
                {{ detailRow?.totalJcStepCheckHours }} h
            </a-descriptions-item>
            <a-descriptions-item label="生产总时长">
                {{ detailRow?.totalHours }} h
            </a-descriptions-item>
        </a-descriptions>
    </a-drawer>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";

export default defineComponent({
    props: ["detailRow"],
    emits: ["update:detailRow"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:detailRow", null);
        };

        watch(
            () => props.detailRow,
            newVal => {
                if (newVal !== null) { 
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>
